<template>
  <div class="solution">
    <banner :card-back="cardBack"/>
    <div class="item text-center xl:text-left" v-for="(item,i) in item1" :key="i">
      <div class="mx-auto xl:w-1200">

        <div class="w-full xl:w-6/12 hidden xl:block"
             v-if="!(i%2)">
          <img class="max-w-full max-h-full mx-auto"
               :src="require('../assets/solution/' + item.img)">
        </div>

        <div class="info w-full xl:w-5/12
                    mb-4 xl:mb-0 pt-0 xl:pt-8">
          <p class="title">{{item.title}}</p>
          <div class="line mx-auto my-4 md:my-6 xl:mx-0"></div>
          <p class="describe text-left">{{item.describe}}</p>
<!--          <div class="btn hidden xl:block">申请试用</div>-->
        </div>

        <div class="w-full xl:w-6/12 hidden xl:block"
             v-if="i%2">
          <img class="max-w-full max-h-full mx-auto"
               :src="require('../assets/solution/' + item.img)">
        </div>

        <div class="w-full xl:w-6/12 block xl:hidden">
          <img class="max-w-full max-h-full mx-auto"
               :src="require('../assets/solution/' + item.img)">
        </div>
      </div>
    </div>
    <foot/>
  </div>
</template>

<script>
import banner from '@/components/websiteHeader'
import foot from '@/components/websiteFooter'

export default {
  name: "Solution",
  components: {
    banner,foot
  },
  data() {
    return {
      cardBack: [
        {carouselOne: require('../assets/banner/解决方案/解决方案.jpg')},
      ],
      item1: [
        {title: '数字政府', img: '1-1.png', describe: '政府数字化转型是大势所趋，数字政府建设关乎数字经济发展促进力，受到全国各级政府、企业和广大民众的关注。 各级各地政府在”数字政府“的建设过程中，数据可信服务体系成为关键的支撑平台，打通跨委办厅局之间的数据孤岛，实现面向政务事项的数据共享交换。',},
        {title: '医疗服务', img: '1-2.png', describe: '“健康中国”将人民健康提升为国家战略，面对医疗服务、疾病预防、政府监管、健康管理、慢病管理、分级诊疗以及药物保障、医疗保障等多方面的诸多痛点难点，为迎接复杂而多变的挑战，应对快速数字化转型需求，数据可信以技术核心能力不断支持大健康应用发展，提供全方位医疗健康体系建设的整体解决方案。',},
        {title: '银行服务', img: '1-3.png', describe: '数字化时代的到来，对于业务场景和模式带来了机遇和挑战，金融机构的创新、敏捷、开放的能力建设，对核心交易系统、大数据平台及数据资产管理平台、业务共享能力提出了新的要求，分布式核心、数据中台、业务中台作为下一代的双中台数字化转型建设的技术方向，数据可信系统以业务创新及用户体验升级为目标，达到业务、架构、运维的精妙平衡数据可信致力于帮助金融机构加快自身数字化进程，打造数字化金融服务',},
        {title: '交通服务', img: '1-4.png', describe: '数据可信提供从交通数据接入到数据应用的全链路智能数据构建与管理能力，帮助客户快速形成数据资产、挖掘数据价值、赋能交通业务。',},
        {title: '税务服务', img: '1-5.png', describe: '数字税务利用现代信息技术和移动通讯技术实现移动办公、以及征收管理、税务稽查、和辖区监管等税务执法工作，并可以为公众提供互联网、语音、手机上网及短信四种高效、便捷的税务注册企业查询服务。',},
        {title: '政法服务', img: '1-6.png', describe: '以云计算、大数据、智能化为支撑，结合数据可信全连接能力，为政法行业拓展互联网+政法服务模式。应用平台提供一站式服务支撑，建设协同工作平台，实现跨部门、跨组织、跨区域、跨系统、跨层级的协同办公能力。数智平台通过大数据治理、AI机器学习、区块链等能力，将海量数据进行深度分析挖掘，形成数据带动指挥决策的全面数据运行机制。',},
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.item {
  padding: 4vh 2vw;
  position: relative;

  img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .describe {
    line-height: 30px;
    font-size: 16px;
  }

  .title {
    color: #333;
    font-size: 18px;
    font-weight: bold;
    margin-top: 8%;
  }

  .line {
    width: 5%;
    border: 1px solid #2555FF;
  }

  .btn {
    color: white;
    padding: 0;
    background: #096DD9;
    width: 30%;
    border-radius: 50px;
    text-align: center;
    line-height: 3.5vh;
    margin-top: 2vh;
    font-size: 1.5vh;
    cursor: pointer;
  }
}
.item:nth-child(odd) {
  background: #FBFBFD;
}

.item:nth-child(even) {
}

@media screen and ( max-width: 768px) {
  .item {
    .describe {
      line-height: 24px;
      font-size: 14px;
    }

    p:first-child {
      font-size: 16px;
      margin-top: 2%;
    }
  }

}
</style>